import { useEffect } from 'react';

export const useVideoPlayer = (videoRef, videoUrl, setVideoUrl) => {
  useEffect(() => {
    if (!videoRef.current) return;

    const handleVideoEnd = () => {
      setVideoUrl(null);
    };

    const videoElement = videoRef.current;
    videoElement.addEventListener('ended', handleVideoEnd);

    return () => {
      videoElement.removeEventListener('ended', handleVideoEnd);
    };
  }, [videoRef, videoUrl, setVideoUrl]);
};
