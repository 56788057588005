import { createSlice } from "@reduxjs/toolkit";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../App";

export const watchVenue = (id) => (dispatch) => {
    if (!id) return;

    const docRef = doc(db, `venues`, id);
    return onSnapshot(docRef, async (doc) => {
        if (!doc.exists()) return;
        dispatch(saveVenueData(doc.data()));
    })
}

export const venueSlice = createSlice({
    name: 'venue',
    initialState: {
        name: undefined,
        city:  undefined,
        state: undefined,
        venue: undefined,
        branding: undefined,
    },
    reducers: {
        saveVenueData: (state, action) => {
            state.name = action.payload.name;
            state.city = action.payload.city;
            state.state = action.payload.state;
            state.alias = action.payload.alias;
            state.branding = action.payload.branding ? action.payload.branding : {};
        }
    }
})

export const { saveVenueData } = venueSlice.actions;
export default venueSlice.reducer;