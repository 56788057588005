import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { AnimatePresence } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db } from '../App';
import { motion } from 'framer-motion';

const containerVariant = {
    "hidden": {
        y: -200,
    },
    "visible": {
        y: 0,
    },
    "exit": {
        y: -200,
    }
}

const sponsorVariant = {
    "hidden": {
      x: -20, opacity: 0, width: "80%", position: "absolute",
    },
    "visible": {
      x: 0, opacity: 1, width: "80%", position: "absolute",
    },
    "exit": {
      x: 20, opacity: 0, width: "80%", position: "absolute",
    }
}

const sponsorMessageVariant = {
    open: {
      width: "auto",
      transition: {
        width: { duration: 0.5, ease: "linear" },
      },
    },
    closed: {
      width: 0,
      transition: {
        width: { duration: 0.5, ease: "linear" },
      },
    },
  };

const Sponsors = () => {
    const sponsors = useSelector((state) => state.table.sponsors);
    const branding = useSelector((state) => state.venue.branding);
    const hostScore = useSelector((state) => state.match.hostScore);
    const guestScore = useSelector((state) => state.match.guestScore);
    const [showSponsorMessage, setShowSponsorMessage] = useState(false);
    const [ sponsorLogoUrls, setSponsorLogoUrls] = useState([]);
    const [ sponsorIndex, setSponsorIndex ] = useState(0);
    let sponsorInterval = undefined;
    let messageInterval = undefined;

    const styles = {
        container: {
            position: 'absolute',
            top: 20,
            left: 20,
            fontSize: "23px",
            fontWeight: 'bold',
            border: "solid",
            borderWidth: 5,
            borderColor: branding && branding.themeColor ? branding.themeColor : "#9D4BAD",
            borderRadius: 15,
            width: 350,
            height: 300,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            letterSpacing: 2,
            display: "flex",
            flexDirection: "column",
            color: "#ffffff",
            padding: "12.5px 25px",
        },
        sponsorContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            overflow: "hidden",
        },
        image: {
            objectFit: "contain",
            maxHeight: "70%",
            maxWidth: "80%",
            height: "auto",
            width: "auto",
        },
        sponsorMessageContainer: {
            position: "absolute",
            left: 380,
            top: 20,
            fontSize: "23px",
            fontWeight: 'bold',
            border: "solid",
            borderWidth: 5,
            borderColor: branding && branding.themeColor ? branding.themeColor : "#9D4BAD",
            borderRadius: 15,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "#ffffff",
            padding: "12.5px 25px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            boxSizing: "content-box",
        }
    }

    useEffect(() => {
        if (sponsorInterval) clearInterval(sponsorInterval);
        if (messageInterval) clearInterval(messageInterval);
        
        if (!sponsors || sponsors.length == 0) return;

        // Rotates the sponsor carousel
        sponsorInterval = setInterval(() => {
            setSponsorIndex((prevSponsorIndex) => (prevSponsorIndex + 1) % sponsors.length);
        }, 5000)

        // Shows the support our sponsors message every 20 minutes
        messageInterval = setInterval(() => {
            setShowSponsorMessage(true);
            setTimeout(() => {
                setShowSponsorMessage(false);
            }, 10000)
        }, 20 * 60 * 1000)

        const loadSponsors = async () => {
            const logoUrls = [];
            for (const sponsor of sponsors) {
                const docRef = doc(db, `sponsors`, sponsor);
                const docData = await getDoc(docRef);
                logoUrls.push(docData.data().logoSrc);
            }
            setSponsorLogoUrls(logoUrls);
        }
        loadSponsors();

        return () => {
            if (sponsorInterval) clearInterval(sponsorInterval);
        }
    }, [sponsors])

    useEffect(() => {
        // if (!showSponsorMessage) {
        //     setShowSponsorMessage(true);
        //     setTimeout(() => {
        //         setShowSponsorMessage(false);
        //     }, 5000)
        // }
    }, [hostScore, guestScore])

    return (
        <div>
            <AnimatePresence>
                {sponsors && sponsors.length > 0 && 
                <motion.div
                    style={styles.container}
                    variants={containerVariant}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    >
                        SPONSORED BY
                        <div style={styles.sponsorContainer}>
                            <AnimatePresence>
                                {sponsorLogoUrls[sponsorIndex] && (
                                    <motion.img src={sponsorLogoUrls[sponsorIndex]}
                                        style={styles.image}
                                        key={"sponsor" + sponsorIndex}
                                        variants={sponsorVariant}
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                    />
                                )}
                            </AnimatePresence>
                        </div>
                </motion.div>
                }
            </AnimatePresence>
            <AnimatePresence>
                {showSponsorMessage && (
                    <motion.div
                        key="sponsorMessage"
                        style={styles.sponsorMessageContainer}
                        variants={sponsorMessageVariant}
                        initial="closed"
                        animate="open"
                        exit="closed"
                    >
                        SUPPORT OUR SPONSORS! INFO IN DESCRIPTION.
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    ) 
};

export default Sponsors;
