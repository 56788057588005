import React, { useEffect, useRef, useState } from 'react';
import './App.css';

import { initializeApp } from 'firebase/app';
import 'firebase/database';
import { getFirestore, collection, getDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore';

import Scoreboard from './components/Scoreboard';
import Canvas from './components/Canvas';
import { getDatabase } from 'firebase/database';
import Roster from './components/Roster';
import Cueball from './components/Cueball';
import evowhitelogo from './assets/evosports-logo-white.png';
import BackgroundComponent from './components/BackgroundComponent';
import VenueLogoComponent from './components/VenueLogoComponent';
import { useDispatch, useSelector } from 'react-redux';
import { saveVenueData, watchVenue } from './slices/venueSlice';
import { watchTable } from './slices/tableSlice';
import { watchMatch } from './slices/matchSlice';
import Sponsors from './components/Sponsors';
import { useVideoPlayer } from './hooks/useVideoPlayer';
import Blackscreen from './components/Blackscreen';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpVFrQFD9bF9asUMfoqPTDbd33seUs4mM",
  authDomain: "elosports.firebaseapp.com",
  databaseURL: "https://elosports.firebaseio.com",
  projectId: "elosports",
  storageBucket: "elosports.appspot.com",
  messagingSenderId: "793217318287",
  appId: "1:793217318287:web:530f4c8bb8b2404a789ffa"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const realtimedb = getDatabase(app);

// get the table name from the URL parameter ?table=<tableName>, default to "colin_1"
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const tableParam = urlParams.get('table');
const deviceName = urlParams.get('device');

function App() {
  // tableName should be from URL parameter ?table=<tableName>
  const cueballRef = useRef({});
  const [tableName, setTableName] = useState();
  const [isCommentating, setIsCommentating] = useState(false);
  const [isCommentatorNoBgmEnabled, setIsCommentatorNoBgmEnabled] = useState(false);
  const [isNoBgm, setIsNoBgm] = useState(false);
  const [bgmVolume, setBgmVolume] = useState(1);
  const audioRef = useRef(null);
  const matchId = useSelector(state => state.table.matchId);
  const dispatch = useDispatch();
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const [videoFullscreen, setVideoFullscreen] = useState(false);
  const [blackscreen, setBlackscreen] = useState(true);
  const [youtubeBroadcastId, setYoutubeBroadcastId] = useState(null);


  useVideoPlayer(videoRef, videoUrl, setVideoUrl);

  useEffect(() => {
    var unsubscribeDevices = function() {};

    // if table Name is given, then do nothing
    // else if deviceName is given, then get the table name from the deviceName in the firebase firestore devices collection
    if (tableParam === null && deviceName !== null) {
      // get the table name from the deviceName in the firebase firestore devices collection
      const docRef = doc(db, `devices`, deviceName);
      // listening on docref if the device.table is changed, then update the tableName
      unsubscribeDevices = onSnapshot(docRef, async (doc) => {
        if (doc.exists() && doc.data().table) {
          setTableName(doc.data().table);
          console.log("Table Name via device id: ", doc.data().table);
        }
      });
    }
    else if (tableParam !== null) {
      setTableName(tableParam);
      console.log("Table Name via table param: ", tableParam);
    }

    return () => {
      unsubscribeDevices();
    }
  }, []);

  useEffect(() => {
    var unsubscribeRosters = function() {};
    var unsubscribeTables = function() {};

    if (!tableName) return;

    // getting the commentating status from the firebase firestore rosters collection
    // /rosters/<tableName>/members is an array, if this array exist and contains any item, then the commentating status is true
    if (tableName || tableParam) {
      const docRef = doc(db, `rosters`, tableName ? tableName : tableParam);
      unsubscribeRosters = onSnapshot(docRef, async (docObj) => {
        if (docObj.exists() && docObj.data().members && docObj.data().members.length > 0) {
          setIsCommentating(true);
        }
        else {
          setIsCommentating(false);
        }
      });
    }

    if (tableName || tableParam) {
      const docRef = doc(db, `tables`, tableName ? tableName : tableParam);
      unsubscribeTables = onSnapshot(docRef, async (docObj) => {
        if (docObj.exists() && docObj.data().commentatorNoBgm) {
          setIsCommentatorNoBgmEnabled(docObj.data().commentatorNoBgm);
          console.log("isCommentatorNoBgmEnabled: ", docObj.data().commentatorNoBgm);
        }

        if (docObj.exists() && docObj.data().noBgm) {
          setIsNoBgm(docObj.data().noBgm);
          console.log("isNoBgm: ", docObj.data().noBgm);
        }

        if (docObj.exists() && docObj.data().bgmVolume) {
          setBgmVolume(docObj.data().bgmVolume);
          console.log("bgmVolume: ", docObj.data().bgmVolume);
        }

        if (docObj.exists() && docObj.data().currentBroadcastId) {
          setYoutubeBroadcastId(docObj.data().currentBroadcastId);
          console.log("youtubeBroadcastId: ", docObj.data().currentBroadcastId);
        }
      });
    }

    const venueId = tableName ? tableName.split("_")[0] : "";
    dispatch(watchTable(tableName));
    dispatch(watchVenue(venueId));

    return () => {
      unsubscribeRosters();
      unsubscribeTables();
    }
  }, [tableName])

  useEffect(() => {
    if (!matchId) return;
    
    dispatch(watchMatch());
  }, [matchId])

  useEffect(() => {
    if (audioRef.current) {
      if (isNoBgm) {
        audioRef.current.pause();
        console.log("audioRef.current.pause()");
      }
      else if (!isCommentating) {
        audioRef.current.play();
        console.log("audioRef.current.play()");
      } else {
        // commentators are on
        if (isCommentatorNoBgmEnabled) {
          audioRef.current.pause();
          console.log("audioRef.current.pause()");
        } else {
          audioRef.current.play();
          console.log("audioRef.current.play()");
        }
      }
    }
  }, [isCommentating, isCommentatorNoBgmEnabled, isNoBgm]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = bgmVolume;
    }
  }, [bgmVolume]);

  useEffect(() => {
    if (!tableName) return;

    const playbackDocRef = doc(db, `playback`, tableName);
    const unsubscribePlayback = onSnapshot(playbackDocRef, (doc) => {
      console.log("test");
      if (doc.exists()) {
        console.log("play" + doc.data().fileToPlay);
        setVideoUrl(doc.data().fileToPlay || null);
        setBlackscreen(false);
      }
    });

    // get playbackDocRef data, and if fileToPlayAtStart has a value, update firestore fileToPlay to fileToPlayAtStart
    getDoc(playbackDocRef).then((doc) => {
      if (doc.exists() && doc.data().fileToPlayAtStart) {
        // set video size to 100%
        setVideoFullscreen(true);
        setVideoUrl(doc.data().fileToPlayAtStart || null);
      }
      else {
        setBlackscreen(false);
      }
    });

    return () => {
      unsubscribePlayback();
    }
  }, [tableName]);

  return (
    <div className="overlay-container">

      <audio 
        ref={audioRef}
        style={{ display: 'none' }} 
        src="https://firebasestorage.googleapis.com/v0/b/elosports.appspot.com/o/music%2Fevosports_bgm_1.mp3?alt=media" 
        loop 
      />
      {blackscreen && <Blackscreen />}
      <Scoreboard />
      <Sponsors />
      <Canvas tableId={tableName}/>
      <Cueball x={0} y={0} size={200} className="hidden z-[4000]" tableId={tableName} thisRef={cueballRef}/>
      <Roster tableName={tableName} position={{ top: 50, left: 4 }} />
      <VenueLogoComponent position={{ bottom: 25, left: 25, width: 150 }} venue={tableName ? tableName.split("_")[0] : ""} />
      <VenueLogoComponent position={{ bottom: 25, right: 25, width: 150 }} venue={tableName ? tableName.split("_")[0] : ""} />
      {videoUrl && (
        <video
          ref={videoRef}
          src={videoUrl.includes('//') ? videoUrl : `http://localhost:1144/${videoUrl}`}
          style={{ position: 'absolute', top: '50%', left: '50%', width: videoFullscreen ? '1920px' : '80%', height: videoFullscreen ? '1080px' : '80%', transform: 'translate(-50%, -50%)' }}
          autoPlay
          onEnded={async () => {
            setVideoUrl(null);
            // set video size back to 80%
            setVideoFullscreen(false);
            const playbackDocRef = doc(db, `playback`, tableName);
            await updateDoc(playbackDocRef, { fileToPlay: null });
          }}
        />
      )}
    </div>
  );
}

export default App;
